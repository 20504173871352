export class ConfirmButton{  
    constructor(uiManager){
        this.uiManager = uiManager;
        this.text = "Confirm";
        this.icon = null;

        this.initialise();
    }

    initialise(){
        var self = this;
        this.confirmButton = document.getElementById("confirmButton");
        console.log(this.confirmButton)
        this.confirmButton.innerHTML = this.text;
        this.confirmButton.addEventListener('click',function(event){ 
            self.uiManager.appManager.playAnimation();
        }); 
    }
}