export class Joystick{  
    constructor(uiManager, side){
        this.uiManager = uiManager;
        this.side = side;

        /* this.canvas = canvas;
        this.advancedTexture = advancedTexture;
        this.productRef = product;
        this.buttonSizeStandard = (this.canvas.width/4)-((this.canvas.width/this.canvas.height)*100);
        this.buttonSizeBig = this.buttonSizeStandard*0.9;
        
        this.joystickSide = side;
        if(this.canvas.width < this.canvas.height)
            this.smallestDimension = this.canvas.width;
        else    
            this.smallestDimension = this.canvas.height;
        this.gridJoystick = this.createJoystickGrid();
        this.imageJoystick = this.createJoystickIcon();
        this.buttonJoystickUp = this.createArrowVertical("up");
        this.buttonJoystickDown = this.createArrowVertical("down");
        this.buttonJoystickRight = this.createArrowHorizontal("right");
        this.buttonJoystickLeft = this.createArrowHorizontal("left");
        this.addArrowsToJoystickGrid();
        this.setJoystickVisibility(false);
        this.setActiveArrow(); */

        this.initialise();
    }

    initialise(){
        var self = this;
        this.leftArrow = document.getElementById(this.side+"-Joystick_left");
        this.rightArrow = document.getElementById(this.side+"-Joystick_right");
        this.upArrow = document.getElementById(this.side+"-Joystick_up");
        this.downArrow = document.getElementById(this.side+"-Joystick_down");

        this.leftArrow.addEventListener('click',function(event){ 
            self.uiManager.appManager.playAnimation();
        }); 
        this.rightArrow.addEventListener('click',function(event){ 
            self.uiManager.appManager.playAnimation();
        }); 
        this.upArrow.addEventListener('click',function(event){ 
            self.uiManager.appManager.playAnimation();
        }); 
        this.downArrow.addEventListener('click',function(event){ 
            self.uiManager.appManager.playAnimation();
        }); 
    }

    setActiveArrow(inputRequired){
        this.leftArrow.disabled = true;
        this.rightArrow.disabled = true;
        this.upArrow.disabled = true;
        this.downArrow.disabled = true; 

        if(inputRequired.includes(this.side+"-Joystick")){
            if(inputRequired.includes("Joystick_left"))
                this.leftArrow.disabled = false;
            if(inputRequired.includes("Joystick_right"))
                this.rightArrow.disabled = false;
            if(inputRequired.includes("Joystick_up"))
                this.upArrow.disabled = false;
            if(inputRequired.includes("Joystick_down"))
                this.downArrow.disabled = false;
        } 
    }

    /* createJoystickGrid(){
        var gridJoystick = new GUI.Grid();   
        gridJoystick.isVisible = true;
        gridJoystick.widthInPixels = (this.smallestDimension/2000)*600;
        gridJoystick.heightInPixels = (this.smallestDimension/2000)*600;
        gridJoystick.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
        if(this.joystickSide == "left"){
            gridJoystick.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
            gridJoystick.leftInPixels = 50;
        }
        else{
            gridJoystick.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
            gridJoystick.leftInPixels = -50;
        }
        gridJoystick.topInPixels = -50;
        gridJoystick.addColumnDefinition((this.smallestDimension/2000)*200, true);
        gridJoystick.addColumnDefinition((this.smallestDimension/2000)*200, true);
        gridJoystick.addColumnDefinition((this.smallestDimension/2000)*200, true);
        gridJoystick.addRowDefinition((this.smallestDimension/2000)*165, true);
        gridJoystick.addRowDefinition((this.smallestDimension/2000)*270, true);
        gridJoystick.addRowDefinition((this.smallestDimension/2000)*165, true);
        this.advancedTexture.addControl(gridJoystick);

        return gridJoystick;
    }    
        
    createJoystickIcon(){
        if(this.joystickSide == "left")
            var iconImage = new GUI.Image("joystick_icon", "./textures/JoystickLeft.png");
        else
            var iconImage = new GUI.Image("joystick_icon", "./textures/JoystickRight.png");
        iconImage.widthInPixels = (this.smallestDimension/2000)*200;
        iconImage.heightInPixels = (this.smallestDimension/2000)*270;
        iconImage.shadowColor = new BABYLON.Color3(0.3, 0, 0, 0.4).toHexString();
        iconImage.shadowOffsetX = 2;
        iconImage.shadowOffsetY = 2;
        iconImage.shadowBlur = 8;
        iconImage.color = "transparent"
        return iconImage;
    }

    addArrowsToJoystickGrid(){
        this.gridJoystick.addControl(this.buttonJoystickLeft, 1, 0); 
        this.gridJoystick.addControl(this.buttonJoystickRight, 1, 2); 
        this.gridJoystick.addControl(this.buttonJoystickUp, 0, 1); 
        this.gridJoystick.addControl(this.buttonJoystickDown, 2, 1); 
        this.gridJoystick.addControl(this.imageJoystick, 1, 1); 
    }

    createArrowHorizontal(direction){ 
        var product = this.productRef;
        var arrow = new GUI.Button(this.joystickSide+"JoystickArrow"+direction);
        arrow.widthInPixels = (this.smallestDimension/2000)*200;
        arrow.heightInPixels = (this.smallestDimension/2000)*270;
        arrow.opacity = 0.2;
        arrow.color = "transparent"
      
        if(direction == "left")
            var iconImage = new GUI.Image("joystick_icon", "./textures/ArrowLeft.png");
        else
            var iconImage = new GUI.Image("joystick_icon", "./textures/ArrowRight.png");
        iconImage.color = "transparent";
        iconImage.shadowColor = new BABYLON.Color3(0.3, 0, 0, 0.4).toHexString();
        iconImage.shadowOffsetX = 2;
        iconImage.shadowOffsetY = 2;
        iconImage.shadowBlur = 8;
        arrow.addControl(iconImage);
        arrow.onPointerClickObservable.add(function(point, data) {
            product.appManager.uiManager.checkJoystickInput(data.currentTarget.name);
            window.navigator.vibrate(50);
        });

        return arrow;
    }

    createArrowVertical(direction){ 
        var product = this.productRef;
        var arrow = new GUI.Button(this.joystickSide+"JoystickArrow" +direction);
        arrow.widthInPixels = (this.smallestDimension/2000)*200;
        arrow.heightInPixels = (this.smallestDimension/2000)*165;
        arrow.opacity = 0.2;
        arrow.color = "transparent"
        
        if(direction == "down")
            var arrowImage = new GUI.Image("joystick_icon", "./textures/ArrowDown.png");
        else
            var arrowImage = new GUI.Image("joystick_icon", "./textures/ArrowUp.png");
        arrowImage.color = "transparent"
        arrowImage.shadowColor = new BABYLON.Color3(0.3, 0, 0, 0.4).toHexString();
        arrowImage.shadowOffsetX = 2;
        arrowImage.shadowOffsetY = 2;
        arrowImage.shadowBlur = 8;
        arrow.addControl(arrowImage);
        arrow.onPointerClickObservable.add(function(point, data) {
            product.appManager.uiManager.checkJoystickInput(data.currentTarget.name);
            window.navigator.vibrate(50);
        });
        return arrow;
    }

    setJoystickVisibility(value) {
        this.gridJoystick.isVisible = value;
        this.gridJoystick.isEnabled = value;
    }

    setActiveArrow(direction) {
        this.buttonJoystickUp.alpha = 0.2;
        this.buttonJoystickUp.isEnabled = false;
        this.buttonJoystickDown.alpha = 0.2;
        this.buttonJoystickDown.isEnabled = false;
        this.buttonJoystickRight.alpha = 0.2;
        this.buttonJoystickRight.isEnabled = false;
        this.buttonJoystickLeft.alpha = 0.2;
        this.buttonJoystickLeft.isEnabled = false;

        if(direction=="up"){
            this.buttonJoystickUp.alpha = 1;
            this.buttonJoystickUp.isEnabled = true;
        }
        else if(direction=="down"){
            this.buttonJoystickDown.alpha = 1;
            this.buttonJoystickDown.isEnabled = true;
        }
        else if(direction=="right"){
            this.buttonJoystickRight.alpha = 1;
            this.buttonJoystickRight.isEnabled = true;
        }
        else if(direction=="left"){
            this.buttonJoystickLeft.alpha = 1;
            this.buttonJoystickLeft.isEnabled = true;
        }
    } */
}