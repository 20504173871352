export class AnimationManager{  
    constructor(appManager){
        this.AppManager = appManager;
        this.animationGroups = appManager.productManager.importedModel.animationGroups;
        this.isResetting = true;
    }

    stopAnimation(){
        this.animationGroups.forEach(function (animationGroup) {
            animationGroup.stop();
        });
    }

    setCurrentAnimationFrame(frame){
        this.isResetting = true;
        this.animationGroups.forEach(function (animationGroup) {
            animationGroup.play();
            animationGroup.goToFrame(frame/30,0);
            animationGroup.stop();
        }); 
        this.isResetting = false;
    }

    updateCurrentAnimation(value){
        this.product.currentAnimation = value;
        this.product.currentStep = 0;
        this.setCurrentAnimationFrame(this.product.procedures[this.product.currentAnimation].steps[this.product.currentStep].start);
    }
    
    playAnimation(startFrame, endFrame){
        //this.product.appManager.cameraManager.checkForCameraTarget();
        this.animationGroups.forEach(function (animationGroup) {
            animationGroup.start(false, 1, startFrame / 24, endFrame / 24); 
        }); 
    }
}