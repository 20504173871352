import { Step } from "./step";

export class Procedure{  
    constructor(procedureData){
        this.procedureName = procedureData.title;
        this.steps = [];
        for (var i = 0; i < procedureData.steps.length; i++) {
            this.steps.push(
                new Step(
                    procedureData.steps[i],i+1
                )
            )
        }
    }
}